/* eslint-disable prettier/prettier */
const avatar1 = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar1.webp';
const avatar2 = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar2.webp';
const avatar3 = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar3.webp';
const avatar4 = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar4.webp';

export interface GarlicEvents {
  _id: string;
  category: string;
  typeOfParticipant: string;
  garlickyFeature?: string;
  address: string;
  date?: string;
  city: string;
  postalCode?: string;
  email?: string;
  website?: string;
  insta?: string;
  hours?: string;
  streetAddress2?: string;
  tel?: string;
  facebook?: string;
  twitter?: string;
  otherSocialMedia?: string;
  details?: string;
  businessName: string;
  restaurant?: string;
  coordinate?: Array<number>;
  businessHours?: string;
  credit?: string;
}

export const getEvents = (): Promise<GarlicEvents[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          _id: '0',
          category: 'Farm',
          typeOfParticipant: "Sell my Ontario garlic from my farm, farmer's market or other location",
          garlickyFeature: 'Farm stand garlic sales, for culinary use and seed.',
          address: '9887 flycreek rd, ',
          date: 'Sept 22-Oct1',
          city: 'North augusta',
          postalCode: 'K0g1r0 ',
          email: 'Brandyjackson1@gmail.com',
          website: 'Flycreekfarm.ca',
          insta: 'Flycreek_farm',
          streetAddress2: '',
          tel: '6132461884',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: 'Farm stand garlic sales, culinary and seed.',
          businessName: 'Flycreek farm',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.779265, 44.741599],
        },
        {
          _id: '1',
          category: 'Farm',
          typeOfParticipant: "Sell my Ontario garlic from my farm, farmer's market or other location",
          garlickyFeature:
            'Selling seed and culinary garlic, also our season end "Scratch and Dent" garlic sale will be taking place',
          address: '919 Sugar Bush Road',
          date: 'Sept 23, 9-1 pm; Sept 28, 3-7pm; Sept 30, 9-1pm',
          city: 'Pakenham',
          postalCode: 'K0A2X0',
          email: 'scott@indiancreekorchard.ca',
          website: 'www.indiancreekorchard.ca',
          insta: '',
          streetAddress2: '',
          tel: '(613) 914-7444',
          facebook: 'https://www.facebook.com/indiancreekorchard',
          twitter: '',
          otherSocialMedia: '',
          details:
            'Selling seed and culinary garlic, also our season end scratch and dent garlic sale will be taking place',
          businessName: 'Indian Creek Orchard Gardens',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.2918124, 45.2504235],
        },
        {
          _id: '2',
          category: 'Farm',
          typeOfParticipant: "Sell my Ontario garlic from my farm, farmer's market or other location",
          garlickyFeature:
            'We grow no-spray, hardneck variety, Music Garlic freshly cured, ready for all your culinary adventures. We also have our much loved and potent Garlic Himalayan Pink Salt gourmet spice.',
          address: 'Riverwalk Commons, 200 Doug Duncan Dr',
          date: 'Sept 23, 8-1pm, Sept 30, 8-1pm',
          city: 'Newmarket ',
          postalCode: 'L3Y 3Y9',
          email: 'Creationfarmson@gmail.com',
          website: 'www.creationfarms.ca',
          insta: '@creationfarmsontario',
          streetAddress2: '',
          tel: '905 478 1551 ',
          facebook: 'N/a',
          twitter: 'N/a',
          otherSocialMedia: 'N/a',
          details:
            "Were at the Newmarket Farmers' Market every Saturday until October 1, and that runs between 8am-1pm. We hope to see you there!",
          businessName: "Creation Farms at Newmarket Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.456375, 44.053327],
        },
        {
          _id: '3',
          category: 'Farm',
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic, Sell a garlic-themed craft or wellness product, Host an in-person or virtual garlic presentation or cooking demo, Host a garlic farmer and/or sell their garlic from my location",
          garlickyFeature:
            "Black garlic products: whole bulbs, sea salt, balsamic vinegar, powder. Regular garlic bulbs. Pickled garlic cloves. Garlic scapes: Pickled garlic scapes & cloves mix, sea salt, powder, We're also at St. Catharines Farmers market Sept 23 (7am-1pm).",
          address: '215 Victoria Ave, Fenwick, ON, L0S 1C0 (all week 10am-4pm)',
          date: 'Sept 22-Oct 1, 10am-4pm',
          city: 'Fenwick',
          postalCode: 'L0S 1C0',
          email: 'info@food4lifemarket.com',
          website: 'www.food4lifemarket.com',
          insta: '@food4lifemarketgarden',
          streetAddress2: '',
          tel: '647 998 9887',
          facebook: 'www.facebook.com/food4lifemarketgarden',
          twitter: '',
          otherSocialMedia: '',
          details:
            'Black garlic products: whole bulbs, sea salt, balsamic vinegar, powder. Regular garlic bulbs. Pickled garlic cloves. Garlic scapes: Pickled garlic scapes & cloves mix, sea salt, powder',
          businessName: 'Food 4 Life Market Garden',
          businessHours: 'Sat 10am-2pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.3769466, 42.9633281],
        },
        {
          _id: '4',
          category: 'Farm',
          typeOfParticipant: '',
          garlickyFeature:
            'Black garlic products: whole bulbs, sea salt, balsamic vinegar, powder. Regular garlic bulbs. Pickled garlic cloves. Garlic scapes: Pickled garlic scapes & cloves mix, sea salt, powder, ',
          address: '91 King Street',
          date: 'Sept 23, 7-1pm',
          city: 'St. Catherines',
          postalCode: 'L2R  3H6',
          email: 'info@food4lifemarket.com',
          website: 'www.food4lifemarket.com',
          insta: '@food4lifemarketgarden',
          streetAddress2: '',
          tel: '647 998 9887',
          facebook: 'www.facebook.com/food4lifemarketgarden',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Food 4 Life Market Garden, vendor at St. Catherines Farmers' Market",
          businessHours: 'Market Hours: Thursdays 8am-2pm; Saturdays 7am-1pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.246527, 43.158798],
        },
        {
          _id: '5',
          category: "Farmers' Market",
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Kids Craft at Farmers' Market - Magical Garlic Wand. ",
          garlickyFeature:
            'We feature vendors selling Ontario garlic. Will run a kids craft for market goers to make a magical garlic wand! ',
          address: 'Royal Canadian Legion Br. 551 ',
          date: 'Sept 30, 8-1pm',
          city: 'Waterdown',
          postalCode: 'L0R 2H0',
          email: 'farmersmarket@waterdownvillage.ca',
          website: 'waterdownfarmersmarket.ca',
          insta: 'https://www.instagram.com/waterdownfarmersmarket/',
          streetAddress2: '79 Hamilton St. N',
          tel: '289-260-6841',
          facebook: 'https://www.facebook.com/waterdownfarmersmarket',
          twitter: 'https://twitter.com/WaterdownMarket',
          otherSocialMedia: '',
          details:
            'We can highlight vendors that have garlic at our market on our social media. We can also plan to do a kids craft for market goers to make a magical garlic wand! ',
          businessName: "Waterdown Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.966605, 43.269015],
        },
        {
          _id: '6',
          category: 'Restaurant',
          typeOfParticipant: 'Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic',
          garlickyFeature: 'Garlic Havarti Burger',
          address: '481 Hodder Avenue',
          date: 'Sept 22-Oct 1',
          city: 'Thunder Bay ',
          postalCode: 'P7A1V3',
          email: 'Beefcakesburgerfactory@gmail.com',
          website: 'www.beefcakesburgerfactory.com',
          insta: '@beefcakeburgerstbay',
          streetAddress2: '',
          tel: '8076301640',
          facebook: 'Beefcakes Burger Factory ',
          twitter: '@beefcakeburger ',
          otherSocialMedia: '',
          details: 'Garlic Havarti Burger',
          businessName: 'Beefcake’s Burger Factory',
          businessHours:
            'Wednesday 11 am-8 pm\r\nThursday 11 am-8 pm\r\nFriday 11 am-9 pm\r\nSaturday 11 am–9 pm\r\nSunday\t11 am–8 pm\r\nMonday 11 am–8 pm\r\nTuesday 11 am–8 pm\r',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-89.182478, 48.468662],
        },
        {
          _id: '7',
          category: 'Restaurant',
          typeOfParticipant: '',
          garlickyFeature: 'Garlic Havarti Burger',
          address: '1408 Brown Street',
          date: 'Sept 22-Oct 1',
          city: 'Thunder Bay ',
          postalCode: 'P7E 2M6',
          email: 'Beefcakesburgerfactory@gmail.com',
          website: 'www.beefcakesburgerfactory.com',
          insta: '@beefcakeburgerstbay',
          streetAddress2: '',
          tel: '',
          facebook: 'Beefcakes Burger Factory ',
          twitter: '@beefcakeburger ',
          otherSocialMedia: '',
          details: 'Garlic Havarti Burger',
          businessName: 'Beefcake’s Burger Factory',
          businessHours: 'Wd-Sat, 11am-9pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-89.281266, 48.36596],
        },
        {
          _id: '8',
          category: 'Restaurant',
          typeOfParticipant: 'Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic',
          garlickyFeature: 'Garlic bread',
          address: '131 Queensway West ',
          date: 'Sept 22-Oct 1',
          city: 'Simcoe',
          postalCode: 'N3Y2M8',
          email: 'info@barrelrestaurant.ca',
          website: 'www.barrelrestaurant.ca',
          insta: 'barrelpizzaspaghettihouse',
          streetAddress2: '',
          tel: '519 426 0068',
          facebook: 'barrelrestaurantsimcoe',
          twitter: '@Barrel87',
          otherSocialMedia: '',
          details: 'sell garlic bread',
          businessName: 'Barrel Restaurant',
          businessHours: 'Sunday to Saturday\r\n11am to 8 pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.312591, 42.845589],
        },
        {
          _id: '9',
          category: 'Restaurant',
          typeOfParticipant: 'Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic',
          garlickyFeature: 'Corned beef cooked with Ontario garlic ',
          address: '3856 Bathurst St.',
          date: 'Sept 22-Oct 1',
          city: 'Toronto ',
          postalCode: 'M3H 3N3',
          email: 'Pancersoriginal@gmail.com',
          website: 'Pancersoriginaldeli.com',
          insta: '',
          streetAddress2: '',
          tel: '9412245369',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: 'Corned beef cooked with Ontario garlic ',
          businessName: "Pancer's Original Deli",
          businessHours: 'Every day 10 am-8pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.435384, 43.740744],
        },
        {
          _id: '10',
          category: "Farmers' Market",
          typeOfParticipant: 'Host a garlic farmer and/or sell their garlic from my location',
          garlickyFeature: 'We feature farmer vendors with their homegrown garlic.  ',
          address: 'Lions Pavilion in Port Rowan Harbour',
          date: 'Sept 22, Sept 29',
          city: 'Port Rowan',
          postalCode: 'N0E 1M0',
          email: 'portrowanfarmersmarket@gmail.com',
          website: 'www.portrowanfarmersmarket.ca',
          insta: "portrowanfarmersmarket's profile picture portrowanfarmersmarket",
          streetAddress2: '11 Sea Queen Rd.',
          tel: '519-586-9532',
          facebook: 'https://www.facebook.com/PortRowanFarmersMarket',
          twitter: 'none',
          otherSocialMedia: '',
          details:
            "We have a few vendors with their homegrown garlic.  Not sure - might ask bakery to do 'garlic' themed food, or run a contest.  ",
          businessName: "Port Rowan Farmers' Market",
          businessHours: 'Fridays 3-6pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.450178, 42.623613],
        },
        {
          _id: '11',
          category: 'Restaurant',
          typeOfParticipant:
            'Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic, I will make a small batch of garlic hot sauce for sale only during that period of time. Very small quantities, maybe 40 bottles. ',
          garlickyFeature: 'Garlicky details to be anniunced',
          address: '1177 Queen St. East ',
          date: 'Sept 22-Oct 1',
          city: 'Toronto',
          postalCode: 'M4M 1L5',
          email: 'hi@jefringale.ca',
          website: 'jefringale.ca',
          insta: '@jefringale',
          streetAddress2: '',
          tel: '416-206-1289',
          facebook: 'https://www.facebook.com/jefringale',
          twitter: '',
          otherSocialMedia: '',
          details: 'Not sure yet. ',
          businessName: 'Fringale',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.332394, 43.662553],
        },
        {
          _id: '12',
          category: "Farmers' Market",
          typeOfParticipant: 'Organize a community garlic-themed event',
          garlickyFeature: 'We feature farmer vendors with their homegrown garlic.  ',
          address: '36 Mississaga Street W',
          date: 'Sept 22-Oct 1',
          city: 'Orillia',
          postalCode: 'L3V 3A6',
          email: 'dsmith@orillia.ca',
          website: 'www.orillia.ca/ofm',
          insta: 'https://www.instagram.com/orilliafarmersmarket/',
          streetAddress2: '',
          tel: '705-238-7955',
          facebook: 'https://www.facebook.com/orilliafarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: 'not sure',
          businessName: "Orillia Farmers' Market",
          businessHours: 'Saturdays 9am-1 pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.420608, 44.608378],
        },
        {
          _id: '13',
          category: 'Farm',
          typeOfParticipant: "Sell my Ontario garlic from my farm, farmer's market or other location",
          garlickyFeature: '',
          address: 'Our Property',
          date: '',
          city: 'Burgessville',
          postalCode: 'N0J1C0',
          email: 'sales@countvongarlic.com',
          website: 'https://countvongarlic.com/',
          insta: '',
          streetAddress2: '52 Church Street West',
          tel: '226747659',
          facebook: '',
          twitter: '@CountVonGarlic',
          otherSocialMedia: '',
          details: 'Selling garlic',
          businessName: 'Count Von Garlic',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.653369, 43.023787],
        },
        {
          _id: '14',
          category: 'Farm',
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic",
          garlickyFeature: 'Garlic jelly ',
          address: '2520 Eglinton Ave West',
          date: 'Sept 29, Oct 1',
          city: 'Mississagua',
          postalCode: '',
          email: 'alphonsas@rogers.com',
          website: 'www.alphonsas.ca',
          insta: 'https://www.instagram.com/alphonsasgourmet/',
          streetAddress2: '',
          tel: '416 558 5791',
          facebook: 'https://www.facebook.com/alphonsasgourmet',
          twitter: '',
          otherSocialMedia: '',
          details: 'Garlic Jelly .. ',
          businessName: "EPO ALPHONSAS GOURMET INC at Erin Mills Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.706349, 43.558113],
        },
        {
          _id: '15',
          category: "Farmers' Market, Special Activity",
          typeOfParticipant: 'Host a garlic farmer and/or sell their garlic from my location',
          garlickyFeature:
            'On the Market Discovery Table, we are featuring a local garlic farmer along with a hostess offering a garlic themed craft for the young ones, free garlic focused recipes and sharing information on the health benefits of garlic, where it originated, and how it is grown.',
          address: '91 King St. ',
          date: 'Sept 23, 7am-1pm',
          city: 'St. Catharines',
          postalCode: 'L2R 7C2',
          email: 'dchakarova@stcatharines.ca',
          website: 'www.stcatharines.ca/marketevents',
          insta: 'https://www.instagram.com/stc_farmmarketvendors/',
          streetAddress2: '',
          tel: '905-932-0639',
          facebook: 'https://www.facebook.com/VendorsSt.CatharinesFM',
          twitter: '',
          otherSocialMedia: '',
          details:
            'On the Market Discovery Table, we will be featuring a local garlic farmer along with a hostess offering a garlic themed craft for the young ones, free garlic focuses recipes and educational components of the health benefits of garlic, where is originated and how it is grown.',
          businessName: 'City of St. Catharines',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.246527, 43.158798],
        },
        {
          _id: '16',
          category: "Farmers' Market",
          typeOfParticipant: "Sell my Ontario garlic from my farm, farmer's market or other location",
          garlickyFeature: '',
          address: 'Palmerston Railway Heritage Museum',
          date: '',
          city: 'Palmerston',
          postalCode: 'N0G 2P0',
          email: 'erin@town.minto.on.ca',
          website: 'mintofarmersmarket.ca',
          insta: '@MintoFarmersMarket',
          streetAddress2: ' 164 William St',
          tel: '519-338-2511 ext 242',
          facebook: '@MintoFarmersMarket',
          twitter: 'N/a',
          otherSocialMedia: '',
          details:
            'not totally sure yet but we plan to have a fall themed craft. Last year we did guess the weight of a pumpkin so maybe guess the weight of three garlics to win them.',
          businessName: "Minto Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-59.163398, 47.590866],
        },
        {
          _id: '17',
          category: "Farmers' Market",
          typeOfParticipant: 'Host a garlic farmer and/or sell their garlic from my location',
          garlickyFeature:
            'We are highlighting our garlic farmers and other vendors who prepare goods made with Ontario garlic.',
          address: '191 Winchester St',
          date: 'Sept 27, 3-7pm',
          city: 'Toronto',
          postalCode: 'M4X 1B8',
          email: 'ellie@cabbagetownmarket.ca',
          website: '',
          insta: '@cabbagetownmarket',
          streetAddress2: '',
          tel: '705-331-2870',
          facebook: "Cabbagetown Farmers' Market",
          twitter: '',
          otherSocialMedia: '',
          details:
            "We're a weekly farmers' market in the Cabbagetown neighbourhood. We will be highlighting our garlic farmers and other vendors who prepare goods made with garlic. We might be doing a roasted garlic workshop/tasting but still in the works. ",
          businessName: "Cabbagetown Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.362713, 43.666948],
        },
        {
          _id: '18',
          category: 'Special Activity',
          typeOfParticipant: "Sell my Ontario garlic from my farm, farmer's market or other location",
          garlickyFeature: 'Mentor New Growers and Gardeners. ',
          address: '298 13th Concession Road',
          date: 'Sept 22-Oct 1, 7am-8pm',
          city: 'Scotland',
          postalCode: 'N0E 1R0',
          email: 'bob@brantcountygarliccompany.com',
          website: 'www.brantcountygarliccompany.com',
          insta: '',
          streetAddress2: '',
          tel: '519-751-9200',
          facebook: 'Brant County Garlic Company',
          twitter: '',
          otherSocialMedia: '',
          details: 'Mentor New Growers and Gardeners',
          businessName: 'Brant County Garlic Company Inc',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.4658347, 43.021205],
        },
        {
          _id: '19',
          category: 'Independent Grocer',
          typeOfParticipant:
            'Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic, Sell a garlic-themed craft or wellness product, Host a garlic farmer and/or sell their garlic from my location',
          garlickyFeature:
            'We have our black garlic products available as well as we will be hosting a farmer to sell their garlic. Details  to be announced',
          address: '335 Jane St. Toronto',
          date: 'Sept 22-Oct 1',
          city: 'Toronto',
          postalCode: 'M6S 3E4',
          email: 'hello@saltnmustard.com',
          website: 'www.saltnmustard.com',
          insta: '@saltnmustard',
          streetAddress2: '',
          tel: '6472188640',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details:
            'We will have our black garlic products available as well as we will be hosting a farmer to sell their garlic.',
          businessName: 'salt + MUSTARD',
          businessHours:
            'Wednesday12–6 p.m.\r\nThursday\t12–6 p.m.\r\nFriday\t12–6 p.m.\r\nSaturday\t10 a.m.–6 p.m.\r\nSunday\tClosed\r\nMonday\tClosed\r\nTuesday\tClosed',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.487372, 43.657267],
        },
        {
          _id: '20',
          category: 'Restaurant, Special Activity',
          typeOfParticipant:
            'Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic, Taste of Manotick  Saturday, Sept. 23, 2023 2 pm - 8 pm. ',
          garlickyFeature: 'Featuring a garlic appetizer during Taste of Manotick.',
          address: '5540 Manotick Main Street, Manotick, Ontario',
          date: 'Sept 23, 2pm-8pm',
          city: 'Manotick, the south part of Ottawa',
          postalCode: 'K4M 1A6',
          email: 'blackdogbistro@bellnet.ca',
          website: 'www.blackdogbistro',
          insta: 'Black Dog Bistro',
          streetAddress2: '',
          tel: '613 692-3779',
          facebook: 'https://www.facebook.com/dot.janz.92',
          twitter: '',
          otherSocialMedia: '',
          details:
            'In the restaurant it will be an appetizer, during taste of Manotick, the giveaway will also probably be an appetizer with garlic decor.',
          businessName: 'Black Dog Bistro',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.684939, 45.22521],
        },
        {
          _id: '21',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '23 Brock Road S',
          date: '',
          city: 'Puslinch',
          postalCode: 'N0B 2J0',
          email: '',
          website: 'http://www.afma.ca',
          insta: 'https://www.instagram.com/Aberfoylefarmersmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/aberfoylefarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Aberfoyle Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.151713, 43.47155],
        },
        {
          _id: '22',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1531 Eglinton Ave. West',
          date: '',
          city: 'Toronto',
          postalCode: 'M6E 2G8',
          email: '',
          website: 'https://afrocaribbeanfarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "African Carribean Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.440701, 43.697518],
        },
        {
          _id: '23',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1531 Eglinton Avenue West',
          date: '',
          city: 'York',
          postalCode: '',
          email: '',
          website: 'https://afrocaribbeanfarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Afro-CaribbeanFarmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.440701, 43.697518],
        },
        {
          _id: '24',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '70 Foster Drive',
          date: '',
          city: 'Sault Ste. Marie',
          postalCode: 'P6A 6V2',
          email: '',
          website: 'http://algomafarmersmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/algomafarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Algoma Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-84.334147, 46.509675],
        },
        {
          _id: '25',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '155 High Street',
          date: '',
          city: 'Almonte',
          postalCode: 'K0A 1A0',
          email: '',
          website: 'http://www.almontefarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Almonte-Farmers-Market-139634092837936/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Almonte Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.193172, 45.224172],
        },
        {
          _id: '26',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Almonte Public Library',
          date: '',
          city: 'Madawaska Street',
          postalCode: 'Arnprior',
          email: '',
          website: 'https://almontefarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Almonte Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.193122, 45.224605],
        },
        {
          _id: '27',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '8728 Howard Ave',
          date: '',
          city: 'Amherstburg',
          postalCode: 'N9V 0C8',
          email: '',
          website: 'http://.amherstburgfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//AmherstburgFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Amherstburg Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-83.004778, 42.059658],
        },
        {
          _id: '28',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '314 Wilson Street East',
          date: '',
          city: 'Ancaster',
          postalCode: 'L9G 2B9',
          email: '',
          website: 'http://shopancasterfarmersmarket.com/',
          insta: 'https://www.instagram.com/@ancasterfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/153101604755265/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Ancaster Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.976465, 43.225863],
        },
        {
          _id: '29',
          category: "Farmers' Market",
          typeOfParticipant:
            'Host a garlic farmer and/or sell their garlic from my location, Organize a community garlic-themed event',
          garlickyFeature:
            "Our weekly farmers' market will have a garlic theme. Farmers will showcase their garlic and artisans/other vendors will showcase their garlic themed products for market consumers. ",
          address: 'Runnymede Presbyterian Church Parking Lot',
          date: '',
          city: 'Toronto',
          postalCode: 'M6S 2C8',
          email: 'annettevillagefarmersmrkt@gmail.com',
          website: 'www.annettevillagemarket.com',
          insta: 'https://www.instagram.com/annette_village_fm/',
          streetAddress2: '680 Annette St. ',
          tel: "416-569-0805 (personal cell - don't share plz)",
          facebook: 'https://www.facebook.com/AnnetteVillageFM',
          twitter: '',
          otherSocialMedia: '',
          details:
            "Our weekly farmers' market will have a garlic theme for this particular market. Farmers will showcase their garlic and artisans/other vendors will showcase their garlic themed products for market consumers ",
          businessName: "Annette Village Farmers' Market",
          businessHours: 'Wednesdays 3-7pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.484818, 43.659109],
        },
        {
          _id: '30',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '49 Wells Street',
          date: '',
          city: 'Aurora',
          postalCode: 'L4G 6S6',
          email: '',
          website: 'https://theaurorafarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Aurora-Farmers-Market-Artisan-Fair',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Aurora Farmers Market & Artisan Fair',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.462289, 43.998337],
        },
        {
          _id: '31',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '670 Cedarview Road',
          date: '',
          city: 'Nepean',
          postalCode: 'Ottawa',
          email: '',
          website: 'https://barrhavenfarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Barrhaven Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.807177, 45.300067],
        },
        {
          _id: '32',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '70 Collier Street',
          date: '',
          city: 'Barrie',
          postalCode: 'L4M 3M3',
          email: '',
          website: 'https://barriefarmersmarket.ca/',
          insta: 'https://www.instagram.com/barriefarmersmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/BarrieFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Barrie Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.686456, 44.391027],
        },
        {
          _id: '33',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '250 John St.',
          date: '',
          city: "Barry's Bay",
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/people/Barrys-Bay-Farmers-Market/100068160454567/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Barry's Bay Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.678014, 45.483512],
        },
        {
          _id: '34',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '6 Clan Gregor Square',
          date: '',
          city: 'Bayfield',
          postalCode: '',
          email: '',
          website: 'https://bayfieldfarmersmarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/bayfieldfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Bayfield Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.69897, 43.562574],
        },
        {
          _id: '35',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Stanley Park',
          date: '',
          city: 'Stanley Avenue',
          postalCode: '',
          email: '',
          website: 'https://www.beechwoodmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Beechwood Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.445611, 43.446187],
        },
        {
          _id: '36',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '182 Pinnacle Street',
          date: '',
          city: 'Belleville',
          postalCode: '',
          email: '',
          website: 'http://bellevillefarmersmarketon.ca/',
          insta: 'https://www.instagram.com/bellevillefarmersmarketon',
          streetAddress2: '',
          tel: '',
          facebook:
            'https://www.facebook.com/pages/category/Farmers-Market/Historical-Belleville-Farmers-Market-979029398955053/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Belleville Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.382065, 44.162904],
        },
        {
          _id: '37',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '300 Borden Street',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'http://bloorborden.com/',
          insta: 'https://www.instagram.com/bloorbordenmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//bloorbordenmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Bloor.Borden MyMarket',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.408727, 43.665098],
        },
        {
          _id: '38',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '354 Queen St.',
          date: '',
          city: 'Blyth',
          postalCode: 'N0M 1H0',
          email: '',
          website: 'https://openfoodnetwork.ca/blyth-community-market/shop',
          insta: 'https://www.instagram.com/blythcommunitymarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/blythcommunitymarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Blyth Community Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.430237, 43.734819],
        },
        {
          _id: '39',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '47 Mansfield St.',
          date: '',
          city: 'Bobcaygeon',
          postalCode: '',
          email: '',
          website: 'http://www.bobcaygeonfarmersmarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Bobcaygeon Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.54676, 44.53394],
        },
        {
          _id: '40',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '18 King St. E.',
          date: '',
          city: 'Bolton',
          postalCode: '',
          email: '',
          website: 'https://www.boltonfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/2018-Bolton-Farmers-Market-210857076378376/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Bolton Farmers' Market - Closed in 2021",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.737477, 43.879471],
        },
        {
          _id: '41',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '425 Holland Street West',
          date: '',
          city: 'Bradford',
          postalCode: '',
          email: '',
          website: 'http://www.bradfordfarmersmarket.ca',
          insta: 'https://www.instagram.com/bradfordfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/BradfordOntarioFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Bradford Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.583335, 44.110668],
        },
        {
          _id: '42',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2 Wellington Street West',
          date: '',
          city: 'Brampton',
          postalCode: '',
          email: '',
          website: 'https://www.brampton.ca/markets',
          insta: 'https://www.instagram.com/experiencebrampton',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@ExperienceBrampton',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Brampton Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.759899, 43.684743],
        },
        {
          _id: '43',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '79 Icomm Dr',
          date: '',
          city: 'Brantford',
          postalCode: '',
          email: '',
          website: 'http://www.brantford.ca/farmersmarket',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/brantfordfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Brantford Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.261872, 43.137336],
        },
        {
          _id: '44',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Kenwick Park Brights Grove',
          date: '',
          city: 'Brights Grove',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@brightsgrovemarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'BRIGHTS GROVE MARKET',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-82.252927, 43.027903],
        },
        {
          _id: '45',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Market Street West',
          date: '',
          city: 'Brockville',
          postalCode: '',
          email: '',
          website: 'http://www.brockvillefarmersmarket.ca',
          insta: 'https://www.instagram.com/@brockvillefarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/BrockvilleFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Brockville Farmer's Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.6829675, 44.5899295],
        },
        {
          _id: '46',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '3 Vipond Road',
          date: '',
          city: 'Brooklin',
          postalCode: '',
          email: '',
          website: 'https://www.whitbyfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@whitbyfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Brooklin Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.962229, 43.95689],
        },
        {
          _id: '47',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '44 Commercial Drive',
          date: '',
          city: "Burk's Falls",
          postalCode: '',
          email: '',
          website: '',
          insta: 'https://www.instagram.com/burksfallsfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/burksfallsfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Burk's Falls Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.404221, 45.609182],
        },
        {
          _id: '48',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '777 Guelph Line',
          date: '',
          city: 'Burlington',
          postalCode: '',
          email: '',
          website: 'https://www.burlingtoncentrefarmersmarket.com/',
          insta: 'https://www.instagram.com/N/A',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/BurlingtonMallFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Burlington Centre Lions Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.793982, 43.347935],
        },
        {
          _id: '49',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '365 Argyle St S',
          date: '',
          city: 'Caledonia',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//caledoniafarmersmarketontario',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Caledonia Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.963375, 43.061268],
        },
        {
          _id: '50',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '40 Dickson Street',
          date: '',
          city: 'Cambridge',
          postalCode: '',
          email: '',
          website: 'https://www.cambridgefarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/FarmersMarketCambridge',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cambridge Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.313387, 43.36007],
        },
        {
          _id: '51',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '66 Front Street South',
          date: '',
          city: 'Campbellford',
          postalCode: '',
          email: '',
          website: 'https://www.campbellfordfarmersmarket.ca/',
          insta: 'https://www.instagram.com/campbellford_farmers_market',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/CampbellfordFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Campbellford Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.798569, 44.306098],
        },
        {
          _id: '52',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '7 Beckwith St',
          date: '',
          city: 'Carleton Place',
          postalCode: '',
          email: '',
          website: 'https://cpfarmersmarket.wordpress.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Carleton-Place-Farmers-Market-103914209656434/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Carleton Place Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.141907, 45.138133],
        },
        {
          _id: '53',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '7 Beckwith Street',
          date: '',
          city: 'Carleton Place',
          postalCode: '',
          email: '',
          website: 'https://cpfarmersmarket.wordpress.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Carleton Place Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.141907, 45.138133],
        },
        {
          _id: '54',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '3790 Carp Road',
          date: '',
          city: 'Carp',
          postalCode: '',
          email: '',
          website: 'https://carpfarmersmarket.ca/',
          insta: 'https://www.instagram.com/carpfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Carp-Farmers-Market',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Carp Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.037821, 45.348509],
        },
        {
          _id: '55',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '108 Caroline Street',
          date: '',
          city: 'Newcastle',
          postalCode: '',
          email: '',
          website: 'http://farmersmarketsontario.com/view-market/?market_id=5a7238a09ebcd7.55263328',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Clarington Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.590387, 43.914841],
        },
        {
          _id: '56',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '84 Whyte Avenue',
          date: '',
          city: 'Dryden',
          postalCode: '',
          email: '',
          website: 'https://www.cloverbeltfarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/cloverbeltfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cloverbelt Country Farmers' Market - Dryden Arena",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-92.838999, 49.78121],
        },
        {
          _id: '57',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '18946 Hwy 17',
          date: '',
          city: 'Oxdrift',
          postalCode: '',
          email: '',
          website: 'https://www.cloverbeltfarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/cloverbeltfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cloverbelt Country Farmers' Market - Oxdrift Hall",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-92.974243, 49.815079],
        },
        {
          _id: '58',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '207 West River Road',
          date: '',
          city: 'Paris',
          postalCode: 'N3L 3E2',
          email: '',
          website: 'https://www.thecobblestonefarmparis.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/thecobblestonefarmparis/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Cobblestone Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.364113, 43.254216],
        },
        {
          _id: '59',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '43 Astrolabe Rd.',
          date: '',
          city: 'Cobden',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/CobdenFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cobden Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.880937, 45.618818],
        },
        {
          _id: '60',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '110 Albert Street',
          date: '',
          city: 'Cobourg',
          postalCode: '',
          email: '',
          website: 'http://www.cobourgfarmersmarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/CobourgFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cobourg Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.170175, 43.958312],
        },
        {
          _id: '61',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '226 5th Street',
          date: '',
          city: 'Cochrane',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/cochranefarmersmarketon/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cochrane Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.02661, 49.0652352],
        },
        {
          _id: '62',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2992 County Rd. 30',
          date: '',
          city: 'Codrington',
          postalCode: 'K0K 1R0',
          email: '',
          website: 'http://www.codringtonfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Codrington-Farmers-Market-885816691461',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Codrington Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.7976532, 44.1540456],
        },
        {
          _id: '63',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Second & Pine Street',
          date: '',
          city: 'Collingwood',
          postalCode: '',
          email: '',
          website: 'https://www.collingwooddowntown.com/farmers-market/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/CollingwoodFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Collingwood Downtown Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.218822, 44.50047975],
        },
        {
          _id: '64',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1007 Mill Street',
          date: '',
          city: 'Combermere',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Combermere Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.616778, 45.364057],
        },
        {
          _id: '65',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '262 Len Purcell Drive',
          date: '',
          city: 'Constance Bay',
          postalCode: '',
          email: '',
          website: 'https://marketinthebay.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/CBayMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Constance Bay Community Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.087905, 45.496933],
        },
        {
          _id: '66',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2 St. Lawrence Dr.',
          date: '',
          city: 'Cornwall',
          postalCode: '',
          email: '',
          website: 'https://www.cornwallkinsmenfarmersmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//cornwallkinsmenfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cornwall Kinsmen Farmers' Market - Online Only",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-74.702931, 45.016959],
        },
        {
          _id: '67',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1725 Gerrard Street',
          date: '',
          city: 'East Toronto',
          postalCode: '',
          email: '',
          website: 'https://www.courtyardfarmersmkt.ca/',
          insta: 'https://www.instagram.com/courtyardfarmersmarket/',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Courtyard and Scarborough Farmers' Markets",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.316073, 43.676355],
        },
        {
          _id: '68',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '130 King Street',
          date: '',
          city: 'London',
          postalCode: '',
          email: '',
          website: 'http://www.coventmarket.com',
          insta: 'https://www.instagram.com/cgoutdoorfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/coventgardenmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Covent Garden Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.250306, 42.982344],
        },
        {
          _id: '69',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '139 Mill St',
          date: '',
          city: 'Creemore',
          postalCode: '',
          email: '',
          website: 'https://creemorefarmersmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/creemorefarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Creemore Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.105093, 44.324681],
        },
        {
          _id: '70',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Queens Circle',
          date: '',
          city: 'Crystal Beach',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/crystalbeachfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Crystal Beach Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.06136115, 42.8659266],
        },
        {
          _id: '71',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1115 Dunning Rd.',
          date: '',
          city: 'Cumberland',
          postalCode: '',
          email: '',
          website: 'http://www.cumberlandfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/cumberlandfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Cumberland Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.402957, 45.514438],
        },
        {
          _id: '72',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '100 Deep River Rd',
          date: '',
          city: 'Deep River',
          postalCode: '',
          email: '',
          website: 'http://www.deepriver.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/groups/263144517039126/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Deep River Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.487555, 46.100878],
        },
        {
          _id: '73',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '200-240 North Service Road West',
          date: '',
          city: 'Oakville',
          postalCode: '',
          email: '',
          website: 'http://www.oakvillecivitan.ca/market.html',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/events/1423757187945323',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Dorval Crossing Civitan Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.6769709, 43.4714047],
        },
        {
          _id: '74',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '484 Pelissier Ave',
          date: '',
          city: 'Windsor',
          postalCode: '',
          email: '',
          website: 'http://dwfm.ca/',
          insta: 'https://www.instagram.com/dwfmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/DWFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Downtown Windsor Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.993205, 48.235442],
        },
        {
          _id: '75',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Dundas Street',
          date: '',
          city: 'Woodstock',
          postalCode: '',
          email: '',
          website: 'https://www.downtownwoodstock.ca/farmers-market.html',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Downtown Woodstock Farmers' Market - CLOSED IN 2021",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.7328622, 43.1338433],
        },
        {
          _id: '76',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '270 Gladstone Avenue',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'http://shop.dufferingrovemarket.ca',
          insta: 'https://www.instagram.com/@dufferingrovemarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/DufferinGroveFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Dufferin Grove Organic Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.430534, 43.650665],
        },
        {
          _id: '77',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: "Hatt Street & Miller's Lane",
          date: '',
          city: 'Dundas',
          postalCode: '',
          email: '',
          website: 'http://dundasfarmersmarket.ca/',
          insta: 'https://www.instagram.com/dundasfarmersmkt',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/DundasMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Dundas Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.954634, 43.26619],
        },
        {
          _id: '78',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '240 Main St.',
          date: '',
          city: 'Dunnville',
          postalCode: '',
          email: '',
          website: 'http://www.dunnvillefarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/dunnvillefarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Dunnville Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.615617, 42.901209],
        },
        {
          _id: '79',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '19000 Leslie Street',
          date: '',
          city: 'East Gwillimbury',
          postalCode: '',
          email: '',
          website: 'http://www.egfm.ca',
          insta: 'https://www.instagram.com/egfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/FarmersMarket1/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "East Gwillimbury Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.45234345, 44.108277],
        },
        {
          _id: '80',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1949 Danforth Avenue',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/MyMarketToronto',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'East Lynn MyMarket',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.315449, 43.684891],
        },
        {
          _id: '81',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '850 Coxwell Avenue',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "East York Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.327903, 43.690988],
        },
        {
          _id: '82',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '342 10th St',
          date: '',
          city: 'Hanover',
          postalCode: '',
          email: '',
          website: '',
          insta: 'https://www.instagram.com/eat_well_market',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/eatwellmarkethanover/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Eat Well Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-98.106151, 49.196067],
        },
        {
          _id: '83',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '8 Foran St',
          date: '',
          city: 'Eganville',
          postalCode: '',
          email: '',
          website: 'http://www.eganvillefarmersmarket.com/',
          insta: 'https://www.instagram.com/eganvillefarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/eganvillefarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Eganville Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.100827, 45.533438],
        },
        {
          _id: '84',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '125 Burnaby Blvd',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'http://www.theeglintonway.com/events/farmers-market-5/',
          insta: 'https://www.instagram.com/EglintonWay/',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Eglinton Way BIA Farmer's Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.412603, 43.704404],
        },
        {
          _id: '85',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '6 Arthur Street North',
          date: '',
          city: 'Elmira',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Elmira Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.557843, 43.600821],
        },
        {
          _id: '86',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '127 East Mill Street',
          date: '',
          city: 'Elora',
          postalCode: '',
          email: '',
          website: 'http://www.elorafarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/EloraFarmersMkt',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Elora Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.42396, 43.684247],
        },
        {
          _id: '87',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2520 Eglinton Avenue West',
          date: '',
          city: 'Mississauga',
          postalCode: '',
          email: '',
          website: 'https://www.erinmillsmarket.com/',
          insta: 'https://www.instagram.com/erinmillsfarmersmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/ErinMillsFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Erin Mills Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.706349, 43.558113],
        },
        {
          _id: '88',
          category: "Farmers' Market",
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Host a garlic farmer and/or sell their garlic from my location, Organize a community garlic-themed event",
          garlickyFeature:
            "To celebrate Ontario Garlic Week the Garden Market at Evergreen Brick Works is hosting a Mini Garlic Festival on Saturday, Sept 30. We'll be hosting 6 local garlic farmers in our Garden Market selling culinary garlic, seed garlic, black garlic and more! Visitors can also purchase bulbs for fall planting, flower arrangements and pumpkins. Happening at the same time is also our popular farmers market with more local farmers and foods.",
          address: '550 Bayview Ave',
          date: 'Sept 30, 9am-1pm',
          city: 'Toronto',
          postalCode: 'M4W 3X8',
          email: '',
          website: 'https://www.evergreen.ca/evergreen-brick-works/markets-food/evergreen-garden-market/',
          insta: '@evergreen_brick_works',
          streetAddress2: '',
          tel: '647-214-1111',
          facebook: 'facebook.com/evergreenbrickworks550',
          twitter: '',
          otherSocialMedia: '',
          details:
            "The Garden Market at Evergreen Brick Works is hosting a Mini Garlic Festival on Saturday, Sept 30 to celebrate Ontario Garlic Week. We'll be hosting 6 local garlic farmers in our Garden Market selling culinary garlic, seed garlic, black garlic and more! Visitors can also purchase bulbs for fall planting, flower arrangements and pumpkins. Happening at the same time is also our popular farmers market with more local farmers and foods.",
          businessName: 'Evergreen Brick Works',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.365628, 43.683783],
        },
        {
          _id: '89',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '162 Livingston Ave',
          date: '',
          city: 'Grimsby',
          postalCode: '',
          email: '',
          website: 'http://www.grimsbyfarmersmarket.com',
          insta: 'https://www.instagram.com/grimsby_farmers_market',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/GrimsbyFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Farmers' Market of Grimsby",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.583768, 43.20057],
        },
        {
          _id: '90',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '45 Bond St.',
          date: '',
          city: 'Fenelon Falls',
          postalCode: '',
          email: '',
          website: 'https://www.fenelonfallsfarmersmarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Fenelon Falls Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.733308, 44.536977],
        },
        {
          _id: '91',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '101 Highland Drive',
          date: '',
          city: 'Flesherton',
          postalCode: '',
          email: '',
          website: 'http://www.fleshertonfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//fleshertonfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Flesherton & District Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.654517, 44.256595],
        },
        {
          _id: '92',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Front Street Parking Lot',
          date: '',
          city: 'Trenton',
          postalCode: '',
          email: '',
          website: 'https://www.quintewest.ca/en/open-for-business/freshonfront.asp?_mid_=92994',
          insta: 'https://www.instagram.com/quinte_west_tourism',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@quintewest',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Front Street Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.5753897, 44.1020939],
        },
        {
          _id: '93',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '4500 Centennial Park Road',
          date: '',
          city: 'Harrowsmith',
          postalCode: '',
          email: '',
          website: 'https://www.frontenacfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/FrontenacFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Frontenac Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.6673946, 44.4094134],
        },
        {
          _id: '94',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '66 Mill Street',
          date: '',
          city: 'Georgetown',
          postalCode: '',
          email: '',
          website: 'http://www.farmersmarketgeorgetown.com',
          insta: 'https://www.instagram.com/georgetownfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Georgetown-Farmers-Market/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Georgetown Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.925329, 43.650717],
        },
        {
          _id: '95',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '20849 Dalton Road',
          date: '',
          city: 'Sutton West',
          postalCode: '',
          email: '',
          website: 'http://www.GeorginaFarmersMarket.ca',
          insta: 'https://www.instagram.com/@georginafarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@GeorginaFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Georgina Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.36295, 44.30629],
        },
        {
          _id: '96',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1 Courthouse Square',
          date: '',
          city: 'Goderich',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/FarmersMarketGoderichBIA/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Goderich Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.711193, 43.743063],
        },
        {
          _id: '97',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '70671 Bluewater Hwy',
          date: '',
          city: 'Grand Bend',
          postalCode: '',
          email: '',
          website: 'https://www.grandbendvillagefm.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/GrandBendMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Grand Bend Village Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.752721, 43.319453],
        },
        {
          _id: '98',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2-4 Gordon St.',
          date: '',
          city: 'Guelph',
          postalCode: '',
          email: '',
          website: 'http://www.guelph.ca/farmersmarket',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/GuelphFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Guelph Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.248157, 43.542166],
        },
        {
          _id: '99',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Head Lake Park',
          date: '',
          city: 'Haliburton Village',
          postalCode: '',
          email: '',
          website: 'http://www.hcfma.ca',
          insta: 'https://www.instagram.com/haliburton_farmers_market',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/HaliburtonCountyFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Haliburton Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.93167, 44.72361],
        },
        {
          _id: '100',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1093 North Shore Road',
          date: '',
          city: 'Carnarvon',
          postalCode: '',
          email: '',
          website: 'http://www.hcfma.ca',
          insta: 'https://www.instagram.com/haliburton_farmers_market',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/HaliburtonCountyFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Haliburton Farmers' Market - Stanhope",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-63.380827, 45.801165],
        },
        {
          _id: '101',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Minden Fairgrounds',
          date: '',
          city: 'Minden',
          postalCode: '',
          email: '',
          website: 'http://www.hcfma.ca',
          insta: 'https://www.instagram.com/haliburton_farmers_market',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/HaliburtonCountyFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Haliburton Farmers' Markets - Minden",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.72493, 44.925444],
        },
        {
          _id: '102',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '35 York Blvd.',
          date: '',
          city: 'Hamilton',
          postalCode: '',
          email: '',
          website: 'https://hamiltonfarmersmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Hamiltonfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Hamilton Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.87034, 43.258934],
        },
        {
          _id: '103',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '252 Mountain Park Avenue',
          date: '',
          city: 'Hamilton',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Hamilton Mountain Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.848613, 43.241685],
        },
        {
          _id: '104',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '235 Queens Quay West',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'http://www.harbourfrontcentre.com/whatson/food.cfm?id=9273&festival_id=0',
          insta: 'https://www.instagram.com/harbourfrontcentre/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/HarbourfrontCentre',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Harbourfront Centre's Local Food Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.383109, 43.63924],
        },
        {
          _id: '105',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '5723 County Rd 46',
          date: '',
          city: 'Havelock',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/HavelockMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Havelock Farmers & Artisan Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.88443, 44.651433],
        },
        {
          _id: '106',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '255 Glenlake Avenue',
          date: '',
          city: 'Toronto',
          postalCode: 'M6P 1G2',
          email: '',
          website: 'https://www.highparkvillagemarket.ca/',
          insta: 'https://www.instagram.com/highparkvillagemarket/',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "High Park Village Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.464421, 43.657355],
        },
        {
          _id: '107',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1635 Canal Rd',
          date: '',
          city: 'Bradford West Gwillimbury',
          postalCode: 'L3Z 4E2',
          email: '',
          website: 'https://hollandmarshfood.ca/',
          insta: 'https://www.instagram.com/holland_marsh_food/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/HollandMarshFoodMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Holland Marsh Food Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.588001, 44.069048],
        },
        {
          _id: '108',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '10 - 16 Manitoba Street',
          date: '',
          city: 'St. Thomas',
          postalCode: '',
          email: '',
          website: 'http://www.hortonfarmersmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Horton Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.185616, 42.779596],
        },
        {
          _id: '109',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Humber Bay park West',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'https://Humberbayshores.org',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Humber Bay Shores Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.479809, 43.613214],
        },
        {
          _id: '110',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '15551 McLaughlin Road',
          date: '',
          city: 'Inglewood',
          postalCode: 'L7C 1J5',
          email: '',
          website: 'https://inglewoodfarmersmarketblog.wordpress.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/InglewoodFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Inglewood Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.928917, 43.794689],
        },
        {
          _id: '111',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '7315 Yonge Street',
          date: '',
          city: 'Innisfil',
          postalCode: '',
          email: '',
          website: 'https://www.innisfilfarmersmarket.ca/',
          insta: 'https://www.instagram.com/innisfilfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//InnisfilFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Innisfil Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.6092, 44.297771],
        },
        {
          _id: '112',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '3311 Simcoe HWY 89',
          date: '',
          city: 'Cookstown',
          postalCode: '',
          email: '',
          website: 'https://www.innisfilfarmersmarket.ca/',
          insta: 'https://www.instagram.com/innisfilfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//InnisfilFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Innisfil Farmers' Market - Tanger Outlet",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.6748063, 44.195454],
        },
        {
          _id: '113',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Johnson Township Community Centre',
          date: '',
          city: 'Desbarats',
          postalCode: '',
          email: '',
          website: 'http://www.johnsonfarmersmarketdesbarats.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/JohnsonFarmersMarketDesbarats/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Johnson Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-83.922914, 46.346326],
        },
        {
          _id: '114',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '275 Keele Street',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'http://junctionmarket.ca/',
          insta: 'https://www.instagram.com/junctionmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/JunctionFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Junction Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.462514, 43.662274],
        },
        {
          _id: '115',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '745 Kanata Avenue',
          date: '',
          city: 'Ottawa',
          postalCode: '',
          email: '',
          website: 'https://www.kanatafarmersmarkets.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Kanata Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.909144, 45.310547],
        },
        {
          _id: '116',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '4 Ash Street',
          date: '',
          city: 'Kapuskasing',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Kapuskasing Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-82.417311, 49.420918],
        },
        {
          _id: '117',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '200 Sanders Street',
          date: '',
          city: 'Kemptville',
          postalCode: '',
          email: '',
          website: 'http://www.kemptvillefarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/KemptvilleFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Kemptville Farmer's Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.648032, 45.018103],
        },
        {
          _id: '118',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Austin Sawmill Park at Victoria Cty Rail Trail',
          date: '',
          city: 'Kinmount',
          postalCode: '',
          email: '',
          website: 'http://www.Kinmountfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Kinmount Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-123.364953, 48.428318],
        },
        {
          _id: '119',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '300 King St E.',
          date: '',
          city: 'Kitchener',
          postalCode: '',
          email: '',
          website: 'http://www.kitchenermarket.ca/en/index.asp',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Kitchener Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.48358, 43.448043],
        },
        {
          _id: '120',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '20 CONCESSION STREET',
          date: '',
          city: 'LAKEFIELD',
          postalCode: '',
          email: '',
          website: 'http://www.lakefieldfarmersmarket.net/',
          insta: 'https://www.instagram.com/lakefieldfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/LakefieldFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Lakefield Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.268284, 44.429869],
        },
        {
          _id: '121',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1352 Lakeshore Road E',
          date: '',
          city: 'Mississauga',
          postalCode: '',
          email: '',
          website: 'https://www.creativehub1352.ca/lakeviewfarmersmarket',
          insta: 'https://www.instagram.com/#lakeviewfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/lakeviewfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Lakeview Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.550564, 43.583843],
        },
        {
          _id: '122',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '11004 Museum Road',
          date: '',
          city: 'Grand Bend',
          postalCode: '',
          email: '',
          website: 'http://www.lambtonshoresmarkets.com',
          insta: 'https://www.instagram.com/grandbendfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/grandbendfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Lambton Shores Market -Grand Bend',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.816203, 43.25092695],
        },
        {
          _id: '123',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '6 Main St. North',
          date: '',
          city: 'Forest',
          postalCode: '',
          email: '',
          website: 'http://www.lambtonshoresmarkets.com',
          insta: 'https://www.instagram.com/grandbendfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/grandbendfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Lambton Shores Markets -Forest',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-82.000865, 43.097832],
        },
        {
          _id: '124',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '150 Greenwood Ave',
          date: '',
          city: 'Toronto',
          postalCode: 'M4L 2R1',
          email: '',
          website: 'https://www.leslievillemarket.com/',
          insta: 'https://www.instagram.com/lesliemarket/?hl=en',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/LeslievilleMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Leslieville Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.329221, 43.669488],
        },
        {
          _id: '125',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '235 Colborne St West',
          date: '',
          city: 'Lindsay',
          postalCode: 'K9V 6A1',
          email: '',
          website: 'http://www.lindsayfarmersmarket.com',
          insta: 'https://www.instagram.com/lindsayfarmersmarketsince1859',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/lindsayfarmersmarketsince1859',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Lindsay Farmers' Market (Wilson Field)",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.7603896, 44.3551902],
        },
        {
          _id: '126',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1 Philip Forbes Street',
          date: '',
          city: "Lion's Head",
          postalCode: 'N0H1W0',
          email: '',
          website: 'https://lionsheadfarmersmarket.com',
          insta: 'https://www.instagram.com/lionsheadmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/LionsHeadFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Lion's Head Farmers Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.253917, 44.987636],
        },
        {
          _id: '127',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '260 Locke Street South',
          date: '',
          city: 'Hamilton',
          postalCode: 'L8P 4B9',
          email: '',
          website: 'http://lsfm.wordpress.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/LockeStreetFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Locke Street Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.887037, 43.252741],
        },
        {
          _id: '128',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '210 Main Street',
          date: '',
          city: 'Ottawa',
          postalCode: 'K1S 3M1',
          email: '',
          website: 'http://mainfarmersmarket.org/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Main Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.677533, 45.408358],
        },
        {
          _id: '129',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '5525 Dickinson St',
          date: '',
          city: 'Manotick',
          postalCode: 'K4M1A2',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/ManotickFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Manotick Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.682835, 45.226967],
        },
        {
          _id: '130',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '97 Main St.',
          date: '',
          city: 'Toronto',
          postalCode: 'M4E 2V6',
          email: '',
          website: 'https://www.greenbeltfresh.ca/market/market-55',
          insta: 'https://www.instagram.com/market55to/?hl=en',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Market 55',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.299134, 43.682102],
        },
        {
          _id: '131',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1680 Richmond Street',
          date: '',
          city: 'London',
          postalCode: 'N6G 3Y9',
          email: '',
          website: '',
          insta: 'https://www.instagram.com/masonvillefarmersmarket/?hl=en',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/MasonvilleMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Masonville Farmers’ Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.279631, 43.024818],
        },
        {
          _id: '132',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Whitecap Pavilion',
          date: '',
          city: 'Kenora',
          postalCode: 'P9N 3P8',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/matiowskifarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Matiowski Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-94.489353, 49.766215],
        },
        {
          _id: '133',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '4 Main Street South',
          date: '',
          city: 'Maxville',
          postalCode: 'K0C 1T0',
          email: '',
          website: 'http://www.maxvillechamber.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Maxville-Farmers-Market',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Maxville Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-74.85515, 45.287489],
        },
        {
          _id: '134',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '3 St. Vincent Street',
          date: '',
          city: 'Meaford',
          postalCode: 'N4L1A1',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/meafordfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Meaford Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.586848, 44.609061],
        },
        {
          _id: '135',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '303 York St.',
          date: '',
          city: 'Kingston',
          postalCode: 'K7K 1R2',
          email: '',
          website: 'https://www.memorialcentrefarmersmarket.ca/',
          insta: 'https://www.instagram.com/memorialmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/MemorialCentreFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Memorial Centre Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.499833, 44.239321],
        },
        {
          _id: '136',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'PO Box 179 Metcalfe',
          date: '',
          city: 'Metcalfe',
          postalCode: 'K0A 2P0',
          email: '',
          website: 'http://metcalfefm.com/',
          insta: 'https://www.instagram.com/metcalfefarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/metcalfefm',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Metcalfe Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-71.909356, 45.399841],
        },
        {
          _id: '137',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '56 Queen St East',
          date: '',
          city: 'Toronto',
          postalCode: 'M5C 2Z3',
          email: '',
          website: 'https://www.metunited.org/collections/the-met-farmers-market',
          insta: 'https://www.instagram.com/metfarmersmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/metfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Metropolitan Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.376663, 43.653863],
        },
        {
          _id: '138',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '35 Canal Drive',
          date: '',
          city: 'Sault Ste Marie',
          postalCode: 'P6A 5P9',
          email: '',
          website: '',
          insta: 'https://www.instagram.com/millmrktssm',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/millmarketssm',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Mill Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-84.345497, 46.515535],
        },
        {
          _id: '139',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '136 Robert Street',
          date: '',
          city: 'Milton',
          postalCode: 'L9T 1G9',
          email: '',
          website: 'http://miltonfarmersmarket.ca/',
          insta: 'https://www.instagram.com/farmersmarketmilton',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/FarmersMarketMilton',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Milton Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.881943, 43.509945],
        },
        {
          _id: '140',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '600 Riverside Dr',
          date: '',
          city: 'Timmins',
          postalCode: 'P4N 8G9',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/mtjoyfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Mountjoy Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.352891, 48.477694],
        },
        {
          _id: '141',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '936215 Airport Rd',
          date: '',
          city: 'Mulmur',
          postalCode: 'L9V 0L3',
          email: '',
          website: 'https://mulmurfarmersmarket.ca',
          insta: 'https://www.instagram.com/Mulmurfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/mulmurmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Mulmur Farmers' Market (Closed in 2021)",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.0299, 44.1354],
        },
        {
          _id: '142',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '100 Queen Street West',
          date: '',
          city: 'Toronto',
          postalCode: 'M5H 2N1',
          email: '',
          website: 'http://www.farmfreshontario.com/',
          insta: 'https://www.instagram.com/ontariofarmfresh',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/OntarioFarmFresh',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Nathan Phillips Square Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.383628, 43.652831],
        },
        {
          _id: '143',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Water Street',
          date: '',
          city: 'Little Current',
          postalCode: 'P0P 1K0',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "NEMI Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.926799, 45.980049],
        },
        {
          _id: '144',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '5212 County Rd. 9',
          date: '',
          city: 'New Lowell',
          postalCode: 'L0M1N0',
          email: '',
          website: 'http://www.clearview.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NewLowellFarmers" market\'="',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "New Lowell Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.96844, 44.359462],
        },
        {
          _id: '145',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Doug Duncan Drive',
          date: '',
          city: 'Newmarket',
          postalCode: 'L3Y 3T8',
          email: '',
          website: 'http://newmarketfarmersmarket.com',
          insta: 'https://www.instagram.com/newmarket_farmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NewmarketMainStFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Newmarket Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.4563748, 44.0533274],
        },
        {
          _id: '146',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '7150 Montrose Road',
          date: '',
          city: 'Niagara Falls',
          postalCode: 'L2H 3N3',
          email: '',
          website: 'https://niagarafalls.ca/living/market/default.aspx',
          insta: 'https://www.instagram.com/@nffarmermarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NiagaraFallsFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Niagara Falls Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.124185, 43.071374],
        },
        {
          _id: '147',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '165 Oak Street',
          date: '',
          city: 'North Bay',
          postalCode: 'P1B 1A2',
          email: '',
          website: 'http://northbayfarmersmarket.com/',
          insta: 'https://www.instagram.com/@northbayfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NorthBayFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "North Bay Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.46366, 46.308712],
        },
        {
          _id: '148',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2397 Roger Stevens Drive',
          date: '',
          city: 'North Gower',
          postalCode: 'K0A 2T0',
          email: '',
          website: 'https://northgowerfarmersmarket.wordpress.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "North Gower Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.723881, 45.128624],
        },
        {
          _id: '149',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Delpark Homes Centre',
          date: '',
          city: 'Oshawa',
          postalCode: 'L1H 7K5',
          email: '',
          website: 'https://northoshawafarmersmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "North Oshawa Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.863532, 43.897556],
        },
        {
          _id: '150',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Mel Lastman Square',
          date: '',
          city: 'North York',
          postalCode: 'M2N 5V7',
          email: '',
          website: 'http://www.northyorkfarmersmarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NorthYorkMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "North York Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.41323750000001, 43.7676505],
        },
        {
          _id: '151',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '11 First Avenue S',
          date: '',
          city: 'Sioux Lookout',
          postalCode: 'P8T 1A8',
          email: '',
          website: 'https://siouxlookout.com/northwest-farmers-market/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Northwest Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-91.9084569, 50.0909425],
        },
        {
          _id: '152',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Oakwood Ave',
          date: '',
          city: 'York',
          postalCode: 'M6E 2T9',
          email: '',
          website: 'http://oakwoodvillage.ca/',
          insta: 'https://www.instagram.com/oakwoodvillagebia/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/OakwoodVillageBIA/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Oakwood Village Urban Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.4384164, 43.6858185],
        },
        {
          _id: '153',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature:
            'The market features farmers selling fresh Ontario garlic, plus vendors selling Black Garlic, Garlic Scape Powder, Garlic Bread (with cheese and without), Garlic Sausages, Honey Garlic Vinegar, Garlic Sauce. All made with Ontario garlic.',
          address: '82 -90 Broadway',
          date: '',
          city: 'Orangeville',
          postalCode: 'L9W 1H9',
          email: '',
          website: 'https://downtownorangeville.ca/farmers-market/',
          insta: 'https://www.instagram.com/downtownoville/?hl=en',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/downtownoville/?rf=852159738233328',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Orangeville Farmers' Market",
          businessHours: 'Sayurdays 8am-1pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.093788, 43.919685],
        },
        {
          _id: '154',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '87 Broadway',
          date: '',
          city: 'Orangeville',
          postalCode: 'L9W 1K1',
          email: '',
          website: 'http://downtownorangeville.ca/orangeville-farmers-market-2018-2',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/orangevillefarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Orangeville Farmers' Market - Winter Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.09417, 43.920285],
        },
        {
          _id: '155',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '84 15th/16th Sideroad',
          date: '',
          city: 'Oro-Medonte',
          postalCode: 'L0L 2X0',
          email: '',
          website: 'http://www.oromedontecc.com/',
          insta: 'https://www.instagram.com/oro_medonte_chamber',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/OroMedonteChamberOfCommerce',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Oro Medonte Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.546594, 44.508058],
        },
        {
          _id: '156',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '55 ByWard Market Square',
          date: '',
          city: 'Ottawa',
          postalCode: 'K1N 9C3',
          email: '',
          website: 'https://www.ottawamarkets.ca/',
          insta: 'https://www.instagram.com/@ottawamarkets',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@MarketsOttawa',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Ottawa ByWard Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.692432, 45.427629],
        },
        {
          _id: '157',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1000 Exhibition Way',
          date: '',
          city: 'Ottawa',
          postalCode: 'K1S 5B4',
          email: '',
          website: 'https://ottawafarmersmarket.ca/',
          insta: 'https://www.instagram.com/@OttawaFarmMkt',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/OttawaFarmMkt',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Ottawa Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.683054, 45.399961],
        },
        {
          _id: '158',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '3191 Riverside Drive',
          date: '',
          city: 'Ottawa',
          postalCode: 'K1V 8N8',
          email: '',
          website: 'http://www.oofmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Ottawa Organic Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.687598, 45.359123],
        },
        {
          _id: '159',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '366 Parkdale Avenue',
          date: '',
          city: 'Ottawa',
          postalCode: 'K1Y 1G8',
          email: '',
          website: 'https://www.ottawamarkets.ca',
          insta: 'https://www.instagram.com/@ottawamarkets',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@MarketsOttawa',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Ottawa Parkdale Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.73007, 45.401418],
        },
        {
          _id: '160',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '3 Britannia ave',
          date: '',
          city: 'Hamilton',
          postalCode: 'L8H 3Z5',
          email: '',
          website: 'https://www.ottawastreetfarmers.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//OttawaStMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Ottawa Street Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.816912, 43.248331],
        },
        {
          _id: '161',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '114 8th Street East',
          date: '',
          city: 'Owen Sound',
          postalCode: 'N4K 1K8',
          email: '',
          website: 'https://www.owensoundfarmersmarket.ca',
          insta: 'https://www.instagram.com/owensoundfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Owen-Sound-Farmers-Market',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Owen Sound Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.9186246, 44.5666851],
        },
        {
          _id: '162',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '20 Pelham Town Square',
          date: '',
          city: 'Fonthill',
          postalCode: 'L0S1E0',
          email: '',
          website: 'https://www.pelham.ca/en/recreation-and-leisure/farmers-market.aspx',
          insta: 'https://www.instagram.com/PelhamFarmersMarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/PelhamFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Pelham Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.284041, 43.044479],
        },
        {
          _id: '163',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Lake St. & Victoria St.',
          date: '',
          city: 'Pembroke ON',
          postalCode: 'K8A 8E2',
          email: '',
          website: 'http://www.pembrokefarmersmarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/thepembrokefarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Pembroke Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.371726, 43.904078],
        },
        {
          _id: '164',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '28 Drummond St E',
          date: '',
          city: 'Perth',
          postalCode: 'K7H 1E9',
          email: '',
          website: 'http://www.perthfarmersmarket.ca',
          insta: 'https://www.instagram.com/perthfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/PerthFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Perth Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.247017, 44.899444],
        },
        {
          _id: '165',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '340 George St N.',
          date: '',
          city: 'Peterborough',
          postalCode: 'K9H 7E8',
          email: '',
          website: 'https://www.peterboroughwednesdayfarmersmarket.ca/',
          insta: 'https://www.instagram.com/ptbowedmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/PeterboroughDowntownFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Peterborough Downtown Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.319561, 44.30393],
        },
        {
          _id: '166',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '360 George Street North',
          date: '',
          city: 'Peterborough',
          postalCode: '',
          email: '',
          website: 'https://www.ptboregionalfarmersmarket.org/',
          insta: 'https://www.instagram.com/@ptborfm',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Ptboregionalfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Peterborough Regional Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.319586, 44.304196],
        },
        {
          _id: '167',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '340 George St N',
          date: '',
          city: 'Peterborough',
          postalCode: '',
          email: '',
          website: 'https://www.peterboroughwednesdayfarmersmarket.ca/',
          insta: 'https://www.instagram.com/@PtboWedMarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/PeterboroughDowntownFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Peterborough Wednesday Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.319561, 44.30393],
        },
        {
          _id: '168',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '369 Fletcher Street',
          date: '',
          city: 'Petrolia',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Petrolia-Farmers-Market-406385209433880/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Petrolia Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-82.147128, 42.882408],
        },
        {
          _id: '169',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1 Ross Street',
          date: '',
          city: 'Picton',
          postalCode: '',
          email: '',
          website: 'http://www.pictontownhall.ca/farmersmarket',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Picton Town Hall Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-77.141392, 44.007856],
        },
        {
          _id: '170',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '59 Charlotte Street',
          date: '',
          city: 'Port Colborne',
          postalCode: '',
          email: '',
          website: 'http://portcolborne.ca/page/farmers_market',
          insta: 'https://www.instagram.com/portcolbornefarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/portcolbornefarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Port Colborne Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.252029, 42.885415],
        },
        {
          _id: '171',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '58 Queen St',
          date: '',
          city: 'Port Hope',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/PHFarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Port Hope Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.293869, 43.948736],
        },
        {
          _id: '172',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '177 Toronto Rd.',
          date: '',
          city: 'Port Hope',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/PHFarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Port Hope Farmers' Market - Home Hardware Location",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.322143, 43.961445],
        },
        {
          _id: '173',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '121 Queen St.',
          date: '',
          city: 'Port Perry',
          postalCode: '',
          email: '',
          website: 'http://www.portperryfarmersmarket.ca',
          insta: 'https://www.instagram.com/portperryfm',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/portperrymarket/?ref=bookmarks',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Port Perry Lakefront Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.941888, 44.106019],
        },
        {
          _id: '174',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '760 Main Street',
          date: '',
          city: 'Powassan',
          postalCode: '',
          email: '',
          website: 'http://www.powassan.net/business/powassan-farmers-market',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/PowassanFarmersMarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Powassan Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.356624, 46.072756],
        },
        {
          _id: '175',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '67 Fowler Street',
          date: '',
          city: 'Richmond',
          postalCode: '',
          email: '',
          website: 'https://www.richmondmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Richmond Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.835887, 45.187881],
        },
        {
          _id: '176',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Box 766',
          date: '',
          city: 'Ridgeway ON',
          postalCode: '',
          email: '',
          website: 'https://historicridgeway.com/',
          insta: 'https://www.instagram.com/RidgewayMarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/RidgewayFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Ridgeway Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.889645, 43.900888],
        },
        {
          _id: '177',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '55 Riverside Drive',
          date: '',
          city: 'New Liskeard',
          postalCode: '',
          email: '',
          website: 'https://www.riversidefarmersmarket.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/theriversidefarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Riverside Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.6692964, 47.5071492],
        },
        {
          _id: '178',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '110 Proctor St.',
          date: '',
          city: 'Sarnia',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Sarnia Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-82.396978, 42.967518],
        },
        {
          _id: '179',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1051 Progress Ave.',
          date: '',
          city: 'Toronto',
          postalCode: 'M1B 5Z6',
          email: '',
          website: 'https://www.scarboroughfarmersmarket.ca/',
          insta: 'https://www.instagram.com/scarboroughfarmersmkt/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Scarborough-Civic-Center-Farmers-Market-116999266698601',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "SCARBOROUGH FARMERS' MARKET",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.233836, 43.792038],
        },
        {
          _id: '180',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Oakridge Park',
          date: '',
          city: '3459 Danforth Ave',
          postalCode: 'M1L 1C9',
          email: '',
          website: 'https://www.scarboroughfarmersmarket.ca/',
          insta: 'https://www.instagram.com/scarboroughfarmersmkt/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Scarborough-Civic-Center-Farmers-Market-116999266698601',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "SCARBOROUGH FARMERS' MARKET",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.279267, 43.69304],
        },
        {
          _id: '181',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1051 Progress Avenue',
          date: '',
          city: 'Scarborough',
          postalCode: 'M1B 5Z6',
          email: '',
          website: 'https://www.scarboroughfarmersmarket.ca/',
          insta: 'https://www.instagram.com/scarboroughfarmersmkt/',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "SCARBOROUGH FARMERS' MARKET - Rosebank Park",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.233836, 43.792038],
        },
        {
          _id: '182',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'The Brick parking lot',
          date: '',
          city: 'Cornwall',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/SeawayValleyGrowersCornwallFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Seaway Valley Growers Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-63.14381, 46.2658],
        },
        {
          _id: '183',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1079A Sheshegwaning Road',
          date: '',
          city: 'Sheshegwaning ON',
          postalCode: '',
          email: '',
          website: '',
          insta: 'https://www.instagram.com/sheshegwaningfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/sheshegwaningfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Sheshegwaning Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-82.8462718, 45.9053171],
        },
        {
          _id: '184',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '172 South Drive',
          date: '',
          city: 'Simcoe',
          postalCode: '',
          email: '',
          website: 'https://simcoefarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/SimcoeMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Simcoe Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.306233, 42.827053],
        },
        {
          _id: '185',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Sorauren Park',
          date: '',
          city: '50 Wabash Ave.',
          postalCode: 'M6R1N2',
          email: '',
          website: 'https://www.soraurenmarket.com/',
          insta: 'https://www.instagram.com/soraurenmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Sorauren-Farmers-Market-1171261023023592',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Sorauren Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.443366, 43.647913],
        },
        {
          _id: '186',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '220 Owen Sound Street',
          date: '',
          city: 'Dundalk',
          postalCode: 'N0C1B0',
          email: '',
          website: 'https://www.southgatecommunityfarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/southgatecommunityfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Southgate Community Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.39143, 44.170741],
        },
        {
          _id: '187',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '33 Queen Street West',
          date: '',
          city: 'Elmvale',
          postalCode: '',
          email: '',
          website: 'https://www.springwater.ca/en/business-and-development/farming-and-agriculture.aspx',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Springwater Farmers' Market Elmvale",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.867811, 44.582596],
        },
        {
          _id: '188',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2333 Victoria Park Ave',
          date: '',
          city: 'North York',
          postalCode: 'M1R 1W6',
          email: '',
          website: 'https://www.scarboroughfarmersmarket.ca/st-andrews-pop-up',
          insta: 'https://www.instagram.com/scarboroughfarmersmkt/',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "St. Andrew's Harvest Pop Up Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.317978, 43.765314],
        },
        {
          _id: '189',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '92 Front Street East',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'https://www.stlawrencemarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//StLawrenceMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "St. Lawrence Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.371988, 43.649444],
        },
        {
          _id: '190',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: "1 Veteran's Circle",
          date: '',
          city: 'St. Marys',
          postalCode: '',
          email: '',
          website: 'http://www.stmarysfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com//FarmersMarketofStMarys/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "St. Marys Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.142195, 43.259456],
        },
        {
          _id: '191',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '220 Huron St.',
          date: '',
          city: 'Stayner',
          postalCode: '',
          email: '',
          website: 'https://www.discoverclearview.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/DiscoverClearview',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Stayner Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.08635, 44.419148],
        },
        {
          _id: '192',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '2508 Stevensville Road',
          date: '',
          city: 'Stevensville',
          postalCode: '',
          email: '',
          website: 'http://www.forterieconservationclub.ca/',
          insta: 'https://www.instagram.com/stevensvillemarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/stevensvillefarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Stevensville Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.053631, 42.943193],
        },
        {
          _id: '193',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '6154 Abbott St E',
          date: '',
          city: 'Stittsville',
          postalCode: '',
          email: '',
          website: 'https://atthebarn.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Stittsville Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.919626, 45.259099],
        },
        {
          _id: '194',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '10 Neighbourhood Lane',
          date: '',
          city: 'Unit 201',
          postalCode: 'M8Y 0C5',
          email: '',
          website: 'https://www.stonegatechc.org/',
          insta: 'https://www.instagram.com/stonegatefarmersmarket/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/StonegateCommunityHealthCentre/?ref=ts&fref=ts',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Stonegate Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.488877, 43.637702],
        },
        {
          _id: '195',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '19 Civic Avenue',
          date: '',
          city: 'Stouffville',
          postalCode: '',
          email: '',
          website: 'https://l4a.ca/experience/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/DT@stouffville',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Stouffvillle Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.245522, 43.970866],
        },
        {
          _id: '196',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '353 McCarthy Road',
          date: '',
          city: 'Stratford',
          postalCode: '',
          email: '',
          website: 'https://www.stratfordagriculturalsociety.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/sdas1841?ref=hl',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Stratford Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.983551, 43.386188],
        },
        {
          _id: '197',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '340 Highway 20 R.R.',
          date: '',
          city: 'Hamilton',
          postalCode: '',
          email: '',
          website:
            'https://www.terragreenhouses.com/winter-market-2020-hub/?fbclid=IwAR3v_bupe39eEUS_ZWXBKmSO3eVJD3R1zcXQiGqgUiT4G8_yYLB67DrRgpY',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/events/456765645249254/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'TERRA Winter Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.746054, 43.165755],
        },
        {
          _id: '198',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '55 Byward Market Square',
          date: '',
          city: 'Ottawa',
          postalCode: '',
          email: '',
          website: 'https://www.ottawamarkets.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'The Byward Public Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.692432, 45.427629],
        },
        {
          _id: '199',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '233 Elgin St.',
          date: '',
          city: 'Sudbury',
          postalCode: '',
          email: '',
          website: 'https://www.greatersudbury.ca/play/the-market/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/GreaterSudburyMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'The Greater Market (Sudbury)',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.992081, 46.487471],
        },
        {
          _id: '200',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '5015 Spectrum Way',
          date: '',
          city: 'Mississauga',
          postalCode: '',
          email: '',
          website: 'https://www.spectrumsquare.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/spectrumsquare/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'The Hive at Spectrum Square',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.607024, 43.649768],
        },
        {
          _id: '201',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '111 Garrison Village Dr',
          date: '',
          city: 'Niagara on the Lake',
          postalCode: '',
          email: '',
          website: 'http://marketatthevillage.ca/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'The Market @ The Village',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.095726, 43.245729],
        },
        {
          _id: '202',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1237 Country Rd. 96',
          date: '',
          city: 'Wolfe Island',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'The Market at the Wolfe Island Commons',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-76.3076219, 44.2157726],
        },
        {
          _id: '203',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '56 Queen St. E',
          date: '',
          city: 'Toronto',
          postalCode: 'M5C 2Z3',
          email: '',
          website: 'https://www.metunited.org/events/the-met-farmers-market',
          insta: 'https://www.instagram.com/metfarmersmarket/?hl=en',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/metfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "The Met Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.376663, 43.653863],
        },
        {
          _id: '204',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '120 Rockmosa Drive',
          date: '',
          city: 'Rockwood',
          postalCode: '',
          email: '',
          website: 'http://therockwoodfarmersmarket.ca/',
          insta: 'https://www.instagram.com/therockwoodfarmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/TheRockwoodFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "The Rockwood Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.1485464, 43.6207719],
        },
        {
          _id: '205',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '601 Christie Street',
          date: '',
          city: 'Toronto',
          postalCode: 'M6G 4C7',
          email: '',
          website: 'https://www.thestop.org/farmers-market/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: "https://www.facebook.com/pages/The%20Stop's%20Green%20Barn/370194513098843/",
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "The Stop Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.424097, 43.679963],
        },
        {
          _id: '206',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '31917 Park Street',
          date: '',
          city: 'Wainfleet',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "The Wainfleet Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.37685, 42.921357],
        },
        {
          _id: '207',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '30 Mill Street',
          date: '',
          city: 'Thornbury',
          postalCode: '',
          email: '',
          website: 'https://thornburymarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/ThornburyFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Thornbury Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.449857, 44.561446],
        },
        {
          _id: '208',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '425 Northern Ave',
          date: '',
          city: 'Thunder Bay',
          postalCode: '',
          email: '',
          website: 'http://www.thunderbaycountrymarket.com',
          insta: 'https://www.instagram.com/tbaymarket',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/tbcountrymarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Thunder Bay Country Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-89.243416, 48.397291],
        },
        {
          _id: '209',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Victoriaville Centre Mall',
          date: '',
          city: 'Thunder Bay',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Thunder Bay Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-89.259796, 48.406414],
        },
        {
          _id: '210',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '41 Bridge Street West',
          date: '',
          city: 'Tillsonburg',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/TillsonburgFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Tillsonburg Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.731996, 42.862126],
        },
        {
          _id: '211',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1053 Dundas Street West',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'http://www.tbfm.ca',
          insta: 'https://www.instagram.com/@trinitybellwoodsfm',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/TrinityBellwoodsFarmersMarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Trinity Bellwoods Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.41772, 43.649803],
        },
        {
          _id: '212',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1265 Military Trail',
          date: '',
          city: 'Toronto',
          postalCode: 'M1C 1A4',
          email: '',
          website:
            'https://utsc.utoronto.ca/food/farmers-market?fbclid=IwAR3rbn9p4h41eR513kZML2xROXeIVGGOpXGJL-cvAXIgsLu2AFH76i8pOrg',
          insta:
            'https://www.instagram.com/utscfarmersmarket/?fbclid=IwAR3DbQbk7jqURMwBaeNRgU4icXt64UZ610Fc63XQB2lGC4xxLj2xv3ADDMI',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/UTSCfarmers/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "U OF T SCABOROUGH Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.186291, 43.783891],
        },
        {
          _id: '213',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '29 Lower River Street',
          date: '',
          city: 'Toronto',
          postalCode: 'M5A 1M6',
          email: '',
          website:
            'https://underpassparkmarket.com/?fbclid=IwAR15TTrTOuuiPTmdPoqnXGRDcRAIjGMhaA6UDye3kUUUqha0k_znOrnFKCA',
          insta: 'https://www.instagram.com/underpass_park_farmers_market/',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Corktown.Market/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Underpass Park Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.355047, 43.656037],
        },
        {
          _id: '214',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: 'Downtown Timmins',
          date: '',
          city: 'Timmins',
          postalCode: '',
          email: '',
          website: 'http://www.urbanparkmarket.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/urbanparkmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Urban Park Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.372478, 43.642471],
        },
        {
          _id: '215',
          category: "Farmers' Market",
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic, Host a garlic farmer and/or sell their garlic from my location",
          garlickyFeature:
            'Many farmers with home grown garlic. Many food vendors infusing Ontario garlic in delicious items to eat. ',
          address: '14 Victoria St',
          date: 'Sept 25, 9am-2pm; Oct 1, 9am-2pm',
          city: 'Uxbridge',
          postalCode: 'L9P1B1',
          email: 'uxbridgefarmersmarketca@gmail.com',
          website: 'uxbridgefrmersmarket.ca',
          insta: 'uxbridgefarmersmarketcanada',
          streetAddress2: '',
          tel: '9057155723',
          facebook: 'Uxbridge Farmers Market Canada',
          twitter: 'na',
          otherSocialMedia: 'na',
          details:
            'Many farmers with home grown garlic. Many artisans that put garlic in the products they are making on site to eat. ',
          businessName: 'Uxbridge Farmers Market',
          businessHours: 'Sundays 9am-2pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.126444, 44.109398],
        },
        {
          _id: '216',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '50 Home Ave,',
          date: '',
          city: 'Vankleek Hill',
          postalCode: 'K0B 1R0',
          email: '',
          website: 'http://www.vankleekhillfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/Vankleek-Hill-Farmers-Market',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Vankleek Hill Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-74.649318, 45.521466],
        },
        {
          _id: '217',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '9100 Bathurst Street',
          date: '',
          city: 'Thornhill',
          postalCode: '',
          email: '',
          website: 'http://www.villagemarket.ca',
          insta: 'https://www.instagram.com/@villagemarketatwaldorf',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/VillageMarketOrganic',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Village Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.457654, 43.843385],
        },
        {
          _id: '218',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1774 Mosley Street',
          date: '',
          city: 'Wasaga Beach',
          postalCode: '',
          email: '',
          website: 'https://www.wasagabeachfarmersmarket.ca',
          insta: 'https://www.instagram.com/wasagabeachfarmersmarket?igshid=zcqbxeq0amaj',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/thewasagabeachfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Wasaga Beach Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.06899, 44.47866],
        },
        {
          _id: '219',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '4361 Hamilton Street',
          date: '',
          city: 'Washago',
          postalCode: '',
          email: '',
          website: '',
          insta: 'https://www.instagram.com/washagolions_farmersmarket',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Washago Lions Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.334403, 44.750898],
        },
        {
          _id: '220',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '70 Young Street',
          date: '',
          city: 'Welland',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Welland Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.248827, 42.990119],
        },
        {
          _id: '221',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '320 King Street East',
          date: '',
          city: 'Mount Forest',
          postalCode: '',
          email: '',
          website: 'http://www.simplyexplore.ca/farmers-market.html',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/wellingtonnorthfarmersmarket/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Wellington North Farmer's Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.728651, 43.983325],
        },
        {
          _id: '222',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '177 West Street',
          date: '',
          city: 'Smithville',
          postalCode: '',
          email: '',
          website: 'http://www.westlincoln.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "West Lincoln Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.550943, 43.101824],
        },
        {
          _id: '223',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '1865 Weston Road',
          date: '',
          city: 'Toronto',
          postalCode: '',
          email: '',
          website: 'https://www.greenbeltfresh.ca/market/weston-farmers-market',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Weston Farmers market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.51463, 43.700265],
        },
        {
          _id: '224',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '405 Dundas Street W',
          date: '',
          city: 'Whitby',
          postalCode: '',
          email: '',
          website: 'https://www.whitbyfarmersmarket.ca',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/@whitbyfarmersmarket',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Whitby Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.946173, 43.878344],
        },
        {
          _id: '225',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '594 Berford Street',
          date: '',
          city: 'Wiarton',
          postalCode: '',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Wiarton Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-81.141219, 44.743052],
        },
        {
          _id: '226',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '5020 Hwy #7 at Islington Ave',
          date: '',
          city: 'Vaughan L4L 1T1',
          postalCode: 'L4L 1T1',
          email: '',
          website:
            'http://www.woodbridgefarmersmarket.com/?fbclid=IwAR3lJmelSqh1ZqJuOm-XX_-n6QuhZRRdi8IfJFDrLGVVPeidYGmJhJuo2Xs',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/woodbridgemrkt/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Woodbridge Village Farmers Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.59071, 43.78113],
        },
        {
          _id: '227',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '875 Nellis Street',
          date: '',
          city: 'Woodstock',
          postalCode: 'N4S 4C6',
          email: '',
          website: 'http://woodstockfairgrounds.com',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: "Woodstock Farmers' Market",
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-80.738936, 43.134169],
        },
        {
          _id: '228',
          category: "Farmers' Market",
          typeOfParticipant: '',
          garlickyFeature: 'Market vendor products include Ontario garlic. Contact market for details.',
          address: '7509 Yonge Street',
          date: '',
          city: 'Thornhill',
          postalCode: 'L3T 2B4',
          email: '',
          website: 'https://www.yorkfarmersmarket.com/',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: '',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'York University Market',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.422232, 43.810764],
        },
        {
          _id: '229',
          category: 'Independent Grocer',
          typeOfParticipant: '',
          garlickyFeature:
            'Featuring locally grown garlic and garlic products. Garlic inspired food from our kitchen to be announced in time for Ontario Galric Week.',
          address: '108 Vine Ave',
          date: 'Sept 22-Oct 1',
          city: 'Toronto',
          postalCode: 'M6P 1V7',
          email: '',
          website: 'https://thesweetpotato.ca',
          insta: 'https://www.instagram.com/thesweetpotatotoronto',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/thesweetpotato',
          twitter: 'https://www.twitter.com/thesweetpotato',
          otherSocialMedia: '',
          details: '',
          businessName: 'The Sweet Potato - Junction',
          businessHours: 'Monday to Saturday 9 AM – 8 PM\r\nSunday 10 AM – 8 PM',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.468455, 43.666936],
        },
        {
          _id: '230',
          category: 'Independent Grocer',
          typeOfParticipant: '',
          garlickyFeature: 'Featuring locally grown garlic and garlic products. ',
          address: '1678 Bayview Ave',
          date: 'Sept 22-Oct 1',
          city: 'Toronto',
          postalCode: 'M4G 3C3',
          email: '',
          website: 'https://thesweetpotato.ca',
          insta: 'https://www.instagram.com/thesweetpotatotoronto',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/thesweetpotato',
          twitter: 'https://www.twitter.com/thesweetpotato',
          otherSocialMedia: '',
          details: '',
          businessName: 'The Sweet Potato - Leaside',
          businessHours:
            'Monday        9 am–8 pm\r\nTuesday        9 am–8 pm\r\nWednesday   9 am–8 pm                         \r\nThursday        9 am–8 pm\r\nFriday        9 am–8 pm\r\nSaturday        9 am–8 pm\r\nSunday        10 am–8 pm\r',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.376375, 43.708099],
        },
        {
          _id: '231',
          category: 'Community Organization',
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Sell a garlic-themed craft or wellness product, Historic Kitchen Garden open with staff available to discuss garlic growing tips",
          garlickyFeature:
            "Dundurn Castle's Gift Shop is selling 6 varieties of heirloom garlic grown onsite in the Historic Kitchen Garden. The Garden will be open with staff available to answer questions and share tips on how to grow the best garlic. Locally crafted kitchen accessories will also be available in the Gift Shop. ",
          address: 'Dundurn National Historic Site',
          date: 'Sept 22-Oct 1',
          city: 'Hamilton',
          postalCode: 'L8R 3H1',
          email: 'museums@hamilton.ca',
          website: 'hamiltoncivicmuseums.ca',
          insta: 'hamilton_civicmuseums',
          streetAddress2: '610 York Boulevard',
          tel: '905-546-2424 x2699',
          facebook: 'Hamilton Civic Museums ',
          twitter: 'N/A',
          otherSocialMedia: 'N/A',
          details:
            "Dundurn Castle's Gift Shop will be selling 6 varieties of heirloom garlic grown onsite in the Historic Kitchen Garden. The Garden will be open with staff available to answer questions and share tips on how to grow the best garlic. Locally crafted kitchen accessories will also be available in the Gift Shop. ",
          businessName: 'Dundurn National Historic Site',
          businessHours: 'Tuesday to Sunday, 12:00 PM - 4:00 PM (Closed Mondays)',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.8195205, 43.2005395],
        },
        {
          _id: '232',
          category: "Farmers' Market",
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Host a garlic farmer and/or sell their garlic from my location",
          garlickyFeature:
            'To celebrate Ontario Garlic Week we are hosting garlic grower Paperboat Farms at the Parkdale Market on Sat, Sept 23. We are also featuring our regular vendors selling Ontario garlic products.',
          address: '366 Parkdale Ave ',
          date: 'Sept 23',
          city: 'Ottawa',
          postalCode: 'K1Y1G8',
          email: 'info@ottawamarkets.ca',
          website: 'ottawamarkets.ca',
          insta: 'https://www.instagram.com/ottawamarkets/',
          streetAddress2: '',
          tel: '819-329-4482',
          facebook: 'https://www.facebook.com/MarketsOttawa',
          twitter: '',
          otherSocialMedia: '',
          details: 'Vendors will be engaged in selling garlic and garlic themed products! ',
          businessName: 'Ottawa Markets',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-75.73007, 45.401418],
        },
        {
          _id: '233',
          category: 'Community',
          typeOfParticipant: '',
          garlickyFeature:
            'To celebrate Ontario Garlic Week we are opening our doors to local Ontario garlic producer, OMG GARLIC.',
          address: '16 Yonge Street',
          date: 'Sept 22, 4-7pm',
          city: 'Capreol',
          postalCode: 'P0M 1H0',
          email: '',
          website: '',
          insta: '',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/groups/RCL179/',
          twitter: '',
          otherSocialMedia: '',
          details: '',
          businessName: 'Capreol Royal Canadian Legion Branch 179',
          businessHours: '',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.376418, 43.643259],
        },
        {
          _id: '234',
          category: 'Farm',
          typeOfParticipant:
            "Sell my Ontario garlic from my farm, farmer's market or other location, Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic, Sell a garlic-themed craft or wellness product, Host an in-person or virtual garlic presentation or cooking demo, Host a garlic farmer and/or sell their garlic from my location",
          garlickyFeature: 'We are hosting a Garlic Fete September 24',
          address: '439 feasby',
          date: 'Sept 24',
          city: 'Uxbridge',
          postalCode: 'L9P 1R1',
          email: 'Fnhappyfarm@gmail.com ',
          website: 'Www.fnhappyfarm.ca',
          insta: 'F.n.happyfarm',
          streetAddress2: '',
          tel: '6472839524',
          facebook: 'F.n.happyfarm',
          twitter: '',
          otherSocialMedia: '',
          details: 'Garlic Fete September 24',
          businessName: 'f.N. Happy Farm',
          businessHours: 'Friday, noon to 5, Sunday 8sm to 2pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.190799, 44.110783],
        },
        {
          _id: '235',
          category: 'Restaurant',
          typeOfParticipant: 'Prepare and sell a savoury dish, dessert or beverage, made with Ontario grown garlic',
          garlickyFeature: 'To celebrate Ontario Garlic Week we are preparing a three course garlic dinner.',
          address: '375 Water Street',
          date: 'Sept 22-Oct1',
          city: 'Peterborough',
          postalCode: 'K9H3L7',
          email: 'amanda@amandalas.ca',
          website: 'www.amandalas.ca',
          insta: '@amandalasptbo',
          streetAddress2: '',
          tel: '705-927-1189',
          facebook: 'Amandalasptbo',
          twitter: '',
          otherSocialMedia: '',
          details: 'We will offer a three course garlic dinner',
          businessName: "Amandala's Restaurant",
          businessHours: 'Thursday to Sunday, dinner starting at 5pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-78.319087, 44.305243],
        },
        {
          _id: '236',
          category: 'Restaurant',
          typeOfParticipant: '',
          garlickyFeature: 'Selling our Ontario garlic infused momos.',
          address: '191 Winchester St           ',
          date: '',
          city: 'Toronto',
          postalCode: 'M4X 1B8',
          email: '',
          website: 'https://www.nepalimomo.ca/',
          insta: 'https://www.instagram.com/nepalimomoto',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NepaliMomoTO/',
          twitter: 'https://www.twitter.com/nepalimomoto',
          otherSocialMedia: '',
          details: '',
          businessName: "Nepali Momos at Cabbagetown Farmers' Market",
          businessHours: 'Tuesdays 3-7pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.362713, 43.666948],
        },
        {
          _id: '237',
          category: 'Restaurant',
          typeOfParticipant: '',
          garlickyFeature: 'Selling our Ontario garlic infused momos.',
          address: '191 Winchester St           ',
          date: '',
          city: 'Toronto',
          postalCode: 'M4X 1B8',
          email: '',
          website: 'https://www.nepalimomo.ca/',
          insta: 'https://www.instagram.com/nepalimomoto',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NepaliMomoTO/',
          twitter: 'https://www.twitter.com/nepalimomoto',
          otherSocialMedia: '',
          details: '',
          businessName: "Nepali Momos at Bloor Borden Farmers' Market",
          businessHours: 'Wednesdays 3-7pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.362713, 43.666948],
        },
        {
          _id: '238',
          category: 'Restaurant',
          typeOfParticipant: '',
          garlickyFeature: 'Selling our Ontario garlic infused momos.',
          address: '191 Winchester St           ',
          date: '',
          city: 'Toronto',
          postalCode: 'M4X 1B8',
          email: '',
          website: 'https://www.nepalimomo.ca/',
          insta: 'https://www.instagram.com/nepalimomoto',
          streetAddress2: '',
          tel: '',
          facebook: 'https://www.facebook.com/NepaliMomoTO/',
          twitter: 'https://www.twitter.com/nepalimomoto',
          otherSocialMedia: '',
          details: '',
          businessName: "Nepali Momos at Withrow Park Farmers' Market",
          businessHours: 'Saturdays 9am-1pm',
          credit: 'Ontario Garlic Week is a project of Toronto Garlic Festival (an Ontario not-for-profit)',
          coordinate: [-79.362713, 43.666948],
        },
      ]);
    }, 10);
  });
};
